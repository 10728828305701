// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-templates-about-template-js": () => import("./../../../src/templates/about-template.js" /* webpackChunkName: "component---src-templates-about-template-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-event-list-template-js": () => import("./../../../src/templates/event-list-template.js" /* webpackChunkName: "component---src-templates-event-list-template-js" */),
  "component---src-templates-event-template-js": () => import("./../../../src/templates/event-template.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-templates-index-template-js": () => import("./../../../src/templates/index-template.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-join-template-js": () => import("./../../../src/templates/join-template.js" /* webpackChunkName: "component---src-templates-join-template-js" */)
}

